@import '~@beewise/theme/build/variables';
.heat-map-legend {
    display: flex;
    align-items: center;
    gap: 8px;
    top: 78px;
    padding: 8px;
    .percentage-marks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        font-size: 12px;
        color: $white;

        span {
            line-height: 1;
        }
    }

    .percentage-bar {
        width: 8px;
        height: 120px;
        border-radius: 2px;
        background: linear-gradient(#2fa62e 0%, #83be37 15%, #8edc6e 30%, #fadf15 50%, #f97316 80%, #dc2626 100%);
    }
}
